export const retailSurveillanceRules = [
  {
    title: 'Retail Analytics',
    items: [
      'Count customers and staff members',
      'Measure queue length',
      'Check shelf occupancy',
      'Count shopping baskets and carts',
      'Assess store crowding',
      'Check for unattended children',
      'Count security personnel',
      'Monitor emergency exits',
      'Check for fallen items on floor',
      'Assess social distancing and mask compliance',
      'Monitor for suspicious activities, weapons and customer assistance needs'
    ]
  }
];
